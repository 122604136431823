@tailwind base;
@tailwind components;
@tailwind utilities;
body
{
  background-color: #F0F0F0;
}
*:focus {
  outline: none !important;
  outline-offset: none !important;
  --tw-ring-inset: none !important;
  --tw-ring-offset-width: none !important;
  --tw-ring-offset-color: #fff !important;
  --tw-ring-color: none !important;
  --tw-ring-offset-shadow: none !important;
  --tw-ring-shadow: none !important;
  box-shadow: unset !important;
  border-color: none !important;
}

p {
  color: #3D3D3D;
  /* font-family: Geist; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.MuiButton-root {
  border-radius: 6px;
}

.pac-container {
  z-index: 10000 !important;
}
