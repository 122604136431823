.app-header {
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  flex-shrink: 0;
  height: 60px;
  justify-content: space-between;
  padding: 1rem 7%;

  .user-profle {
    cursor: pointer;
    align-items: center;
    background: #FFF;
    border-left: 1px solid #EBEBEB;
    border-right: 1px solid #EBEBEB;
    display: flex;
    flex-shrink: 0;
    height: 56px;
    justify-content: space-between;
    width: 192px;

    .data {
      display: flex;
      align-items: center;

      span {
        width: 100px;
        white-space: nowrap;
        overflow: hidden;   
        text-overflow: ellipsis;
      }
    }

    span {
      color: var(--Black-800, #27272A);
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: -0.12px;
      line-height: 170%;
      margin-left: 8px;
    }
  }
}

@media (width <= 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .userInfo {
    margin-top: 0.5rem;
  }
}
